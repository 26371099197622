<template>
  <div class="course-request-list">
    <h5>
      Get started by listing your courses manually or integrating seamlessly
      with our API.
    </h5>
    <p class="crl__content">
      Get started by listing your courses manually or integrating seamlessly
      with our API. Whether you prefer hands-on control or automated efficiency,
      we've got you covered. Choose the method that suits you best and embark on
      your journey to reach a wider audience and empower learners worldwide.
    </p>
    <div class="crl__list">
      <div class="crl__list--div">
        <img src="@/assets/images/partner/courses/sync.svg" alt="sync" />
        <div class="crl-list__content">
          <h6>Get course data from your platform by making an API call</h6>
          <p>
            Access course data instantly through API! With just one call, unlock
            a treasure trove of course details like titles, descriptions,
            schedules, and more. Effortlessly integrate your platform into ours
            to provide users with up-to-date information, enriching their
            learning journey.
          </p>
        </div>
        <LxpButton variant="secondary1" @click="navigateTo('api-get-started')"
          >API integration</LxpButton
        >
      </div>
      <div class="crl__list--div">
        <img src="@/assets/images/partner/courses/link.svg" alt="link" />
        <div class="crl-list__content">
          <h6>List courses manually</h6>
          <p>
            Easily add your courses directly into our platform. Simply input the
            course details such as course link, title, description and schedule.
            Take full control of your course listings and get started in
            minutes!
          </p>
        </div>
        <LxpButton variant="secondary1" @click="navigateTo('list-a-course')"
          >List Manually</LxpButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  methods: {
    navigateTo(type) {
      this.$router.push({ path: `/partner/courses/${type}` });
    }
  }
};
</script>

<style lang="scss" scoped>
.course-request-list {
  @include partner-div-background();
  h5 {
    @include subtitle-large(b$brand-neutral-900);
    margin-bottom: 8px;
  }
  .crl__content {
    @include label-large;
    margin-bottom: 16px;
  }
  .crl__list {
    .crl__list--div {
      @include horizontal-space-between;
      background: $brand-secondary1-50;
      border-radius: 16px;
      padding: 16px;
      gap: 24px;
      &:first-child {
        margin-bottom: 24px;
      }
      .crl-list__content {
        h6 {
          @include body-regular(500);
          margin-bottom: 0;
        }
        p {
          @include label-large();
          margin-bottom: 0;
        }
      }
      .lxp-button {
        flex: none;
      }
    }
  }
}
</style>
