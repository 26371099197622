<template>
  <div class="courses-list">
    <div class="cl-heading">
      <div class="cw-main__count">{{ count }} results</div>
      <img
        :src="
          sort
            ? require('@/assets/images/partner/courses/sort-up.svg')
            : require('@/assets/images/partner/courses/sort-down.svg')
        "
        alt="sort"
        class="cl-heading__sort"
        v-if="count !== 0"
        @click="onSort"
      />
    </div>
    <div class="cw-main__results">
      <LxpPaginationWrapper
        ref="courselistwrapper"
        :dataFetchURL="dataFetchURL"
        :authToken="`Bearer ${$keycloak.token}`"
        nextPageLabel="next"
        :pageSizeValue="20"
        showNumberPagination
        @count="updateCount"
        @card-subtitle-click="onSubTitleClick"
      >
        <template v-slot:emptyState>
          <div class="cw-main__empty">
            <div class="cw-main__results--empty">
              <img
                src="@/assets/images/partner/courses/empty-courses.svg"
                alt="empty"
                class="cw-main__empty--img"
              />
              <list-empty-state
                :search="search"
                :hasFilters="showFilterPills"
                v-if="search !== '' || showFilterPills"
              />
              <div class="cw-main__results--empty-div" v-else>
                <p>Unfortunately, there are no courses available yet.</p>
                <span
                  >This may be due to an API call issue or because the
                  connection has not been made yet.</span
                >
              </div>
            </div>
          </div>
        </template>

        <template v-slot:data="slotProps">
          <div class="w-100">
            <div
              class="cw-main__results--card"
              v-for="(course, i) in slotProps.data"
              :key="i"
            >
              <LxpCourseCard
                :card-sub-title="course.org_short_name"
                :card-title="course.name"
                :card-image="
                  course.course_image_url
                    ? course.course_image_url
                    : require('@/assets/images/partner/courses/thumbnail.png')
                "
                :default-image="
                  require('@/assets/images/partner/courses/thumbnail.png')
                "
                tag-class="ls-resources__card--tag"
                footer-class="course__card--footer"
                @card-click="$parent.$emit('card-click', course.course_id)"
                @card-subtitle-click="
                  $parent.$emit('card-subtitle-click', course.org_short_name)
                "
                :id="`course-card-${course.course_id}`"
              >
                <template #footer>
                  <div class="course-card__footer">
                    <div
                      :class="[
                        'course-card__footer--div',
                        `course-card__${course.pacing}`
                      ]"
                    >
                      <img
                        :src="
                          course.pacing === 'self'
                            ? require('@/assets/images/partner/courses/self-paced-icon.svg')
                            : require('@/assets/images/partner/courses/instructor-led-icon.svg')
                        "
                        alt="pacing"
                      />
                      <p class="mb-0">{{ pacing(course) }}</p>
                    </div>
                    <div class="course-card__footer--div course-card__language">
                      <img
                        src="https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/language.svg"
                        alt="pacing"
                      />
                      <p class="mb-0">{{ course.language }}</p>
                    </div>
                  </div>
                </template>
              </LxpCourseCard>
              <course-details-popover :course="course" />
            </div>
          </div>
        </template>
      </LxpPaginationWrapper>
    </div>
  </div>
</template>

<script>
import { LxpCourseCard, LxpPaginationWrapper } from "didactica";
import CourseDetailsPopover from "../Shared/CourseDetailsPopover.vue";
import ListEmptyState from "../Shared/ListEmptyState.vue";
export default {
  props: {
    dataFetchURL: {
      type: String,
      required: true
    },
    sort: {
      type: Boolean,
      required: true
    },
    search: {
      type: String,
      required: true
    },
    showFilterPills: {
      type: Boolean,
      required: true
    }
  },
  components: {
    LxpCourseCard,
    LxpPaginationWrapper,
    CourseDetailsPopover,
    ListEmptyState
  },
  data() {
    return {
      count: 0
    };
  },
  methods: {
    pacing(course) {
      return course.pacing === "self" ? "Self-paced" : "Instructor-led";
    },
    updateCount(c) {
      this.count = c;
    },
    onSubTitleClick() {
      // TODO org click
    },
    onSort() {
      this.$emit("sort");
    }
  }
};
</script>

<style lang="scss" scoped>
.courses-list {
  .cl-heading {
    display: flex;
    justify-content: space-between;
    .cl-heading__sort {
      width: 28px;
      height: 28px;
      background: $brand-neutral-0;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background: $brand-secondary1-50;
      }
    }
  }
  .cw-main__results {
    clear: both;
    display: table;
    width: 100%;
    @include searchResult;
    .w-100 {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(clamp(25% - 24px, 218px, 100%), 1fr)
      );
      gap: 24px 8px;
      margin-bottom: 24px;
      @include course-card-footer;
    }
  }
  .cw-main__count {
    margin: 16px 0;
    @include label-large($brand-neutral-300);
  }
}
</style>
<style lang="scss">
@include course-card-pointer;
.cw-main__empty {
  @include empty-state;
}
</style>
