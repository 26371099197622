<template>
  <div class="courses-wrapper">
    <LxpLoader v-if="isLoading" />

    <div v-else>
      <get-started v-if="latestCourses.length === 0 && !isFetchingCourses" />
      <div class="courses-list-wrapper" v-else>
        <div class="cw-header">
          <SearchBar
            placeholder="Search by course name or ID..."
            @search="onSearch"
          />
          <filters :menu="menu" @filterChange="setFilterObj" />
        </div>
        <div class="cw__pills">
          <filter-pills :filters="filter" v-if="showFilterPills" />
        </div>
        <div class="cw-main">
          <p class="cw-main__search-result" v-if="searchText !== ''">
            Showing results for <span>{{ searchText }}</span>
          </p>
          <div class="cw-main__progress" v-if="isFetchingCourses">
            <div class="cw-main__count">0 results</div>
            <div class="cw-main__progress--div">
              <LxpProgress
                variant="secondary1"
                :max="100"
                :value="progressValue"
                showProgressValue
              />
              <h6>We are importing content from your API...</h6>
              <p>
                This may take up to 15 sec, please wait while we setup your
                course catalog.
              </p>
            </div>
          </div>
          <b-tabs
            nav-class="justify-content-md-center justify-content-lg-start"
            pills
            content-class="mt-4"
            v-model="tabIndex"
            lazy
            v-else
          >
            <LxpButton
              variant="secondary1"
              @click="$router.push({ name: 'ManualCourseListing' })"
              v-if="isPartnerApiIntegrated"
              className="cw-main__courses--menu"
              >Add Courses</LxpButton
            >
            <LxpMenu
              text="Add courses"
              className="cw-main__courses--menu"
              :items="courseOptions"
              :noCaret="false"
              @select="selectVal"
              v-else
            ></LxpMenu>
            <b-tab title="Future & Ongoing" active>
              <courses-list
                :dataFetchURL="dataFetchUrl"
                :searchText="searchText"
                :sort="sort"
                :search="searchText"
                :showFilterPills="showFilterPills"
                @sort="onSort"
              />
            </b-tab>
            <b-tab title="Archived">
              <courses-list
                :dataFetchURL="dataFetchUrl"
                :searchText="searchText"
                :sort="sort"
                :search="searchText"
                :showFilterPills="showFilterPills"
                @sort="onSort"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LxpButton, LxpLoader, LxpMenu, LxpProgress } from "didactica";
import CoursesList from "./CoursesList.vue";
import SearchBar from "../Shared/SearchBar.vue";
import Filters from "../Shared/Filters.vue";
import FilterPills from "../Shared/FilterPills.vue";
import { mapGetters } from "vuex";
import GetStarted from "./GetStarted.vue";
export default {
  components: {
    LxpButton,
    LxpLoader,
    LxpMenu,
    LxpProgress,
    SearchBar,
    CoursesList,
    Filters,
    FilterPills,
    GetStarted
  },
  data() {
    return {
      isLoading: true,
      tabIndex: 0,
      searchText: "",
      sort: false,
      filter: {
        language: [],
        pacing: ""
      },
      menu: [
        {
          heading: "Language",
          id: "language",
          selected: [],
          collapse: false,
          items: [
            {
              value: "ar",
              text: "العربية"
            },
            {
              value: "zh",
              text: "中文"
            },
            {
              value: "en",
              text: "English"
            },
            {
              value: "fr",
              text: "Français"
            },
            {
              value: "ru",
              text: "Русский"
            },
            {
              value: "es",
              text: "Español"
            }
          ],
          type: "checkbox"
        },
        {
          heading: "Pacing",
          id: "pacing",
          selected: "",
          collapse: false,
          items: [
            { id: "1", value: "instructor", text: "Instructor-led" },
            { id: "2", value: "self", text: "Self-paced" }
          ],
          type: "radio"
        }
      ],
      progressValue: 25,
      courseOptions: [
        { label: "API integration", value: "ApiGetStarted" },
        { label: "Add manually", value: "ManualCourseListing" }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "allConfig",
      "isFetchingCourses",
      "latestCourses",
      "isPartnerApiIntegrated"
    ]),
    dataFetchUrl() {
      return `${this.allConfig.baseURL}/gateway/partner-courses/${this.queryParams}`;
    },
    showFilterPills() {
      return this.filter.pacing !== "" || this.filter.language.length > 0;
    },
    filtering() {
      return this.tabIndex === 0 ? "future_and_ongoing" : "archived";
    },
    ordering() {
      return this.sort ? "updated_at" : "-updated_at";
    },
    languageFilter() {
      let language = this.filter.language.map(l => l.value);
      return language.join(",");
    },
    pacingFilter() {
      return this.filter.pacing !== "" ? this.filter.pacing.value : "";
    },
    queryParams() {
      return `?search=${this.searchText}&ordering=${this.ordering}&filtering=${this.filtering}&language=${this.languageFilter}&pacing=${this.pacingFilter}`;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$store.commit(
        "SET_FETCHING_COURSES",
        JSON.parse(sessionStorage.getItem("loading-courses"))
      );
      if (this.isFetchingCourses) {
        this.isLoading = false;
        let interval = setInterval(() => {
          this.fetchLatestCourses(interval);
        }, 5000);
      } else if (this.latestCourses.length === 0) {
        this.fetchLatestCourses();
      } else {
        this.isLoading = false;
      }

      // fetch patner integration status
      if (!this.isPartnerApiIntegrated) {
        this.$store.dispatch("getPartnerIntegrationStatus");
      }
    },
    onSearch(text) {
      this.searchText = text;
    },
    setFilterObj() {
      this.menu.forEach(fm => {
        if (fm.type === "checkbox") {
          if (fm.selected.length > 0) {
            this.filter[fm.id] = fm.items.filter(fmi =>
              fm.selected.includes(fmi.value)
            );
          } else {
            this.filter[fm.id] = [];
          }
        } else if (fm.type === "radio") {
          if (fm.selected !== "") {
            this.filter[fm.id] = fm.items.find(
              item => item.value === fm.selected
            );
          } else {
            this.filter[fm.id] = "";
          }
        }
      });
    },
    onSort() {
      this.sort = !this.sort;
    },
    fetchLatestCourses(interval) {
      this.$store.dispatch("listLatestCourses", { pageSize: 4 }).then(() => {
        if (this.latestCourses.length > 0 && interval) {
          this.isFetchingCourses = false;
          this.progressValue = this.progressValue + 50;
          sessionStorage.setItem("loading-courses", false);
          clearInterval(interval);
        }
        this.isLoading = false;
      });
    },
    selectVal(r) {
      this.$router.push({ name: r.value });
    }
  }
};
</script>
<style lang="scss">
@import "../../../scss/tab.scss";
.courses-wrapper {
  .cw-main__courses--menu {
    position: absolute;
    top: 16px;
    right: 16px;
    .dropdown-toggle {
      background: $brand-secondary1-400;
      border-radius: 100px;
      color: $brand-neutral-0;
      border: none;
      &:hover,
      &:focus,
      &:active {
        background: $brand-secondary1-400 !important;
        color: $brand-neutral-0;
        border-radius: 100px;
      }
      .lxp-menu--dropdown-button {
        padding: 0 12px;
        &:active,
        &:focus {
          background: transparent !important;
          border-color: none !important;
        }
        .lxp-menu--text {
          color: $brand-neutral-0;
        }
        .lxp-menu--img {
          img {
            filter: invert(92%) sepia(93%) saturate(0%) hue-rotate(202deg)
              brightness(106%) contrast(106%);
          }
        }
      }
    }
    .dropdown-menu {
      margin-top: 5px;
      .dropdown-item {
        &:hover {
          background: $brand-secondary1-50 !important;
          color: $brand-secondary1-400;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@mixin bgStyle {
  padding: 16px;
  background: $brand-neutral-0;
  border-radius: 8px;
}
.courses-wrapper {
  .cw-header {
    @include bgStyle;
    @include flex-horizontal-center;
  }
  .cw__pills {
    margin-top: 24px;
  }
  .cw-main {
    @include bgStyle;
    @include searchResult;
    position: relative;
    .cw-main__progress {
      .cw-main__progress--div {
        @include center;
        text-align: center;
        height: 400px;
        .lxp-progress {
          width: 30%;
          margin-bottom: 24px;
        }
        h6 {
          @include body-regular(500, $brand-neutral-900);
          margin-bottom: 0;
          width: 40%;
        }
        p {
          @include body-regular(400, $brand-neutral-900);
          width: 40%;
        }
      }
    }
    .cw-main__count {
      @include label-large($brand-neutral-300);
    }
  }
}
</style>
