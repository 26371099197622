var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courses-list"},[_c('div',{staticClass:"cl-heading"},[_c('div',{staticClass:"cw-main__count"},[_vm._v(_vm._s(_vm.count)+" results")]),(_vm.count !== 0)?_c('img',{staticClass:"cl-heading__sort",attrs:{"src":_vm.sort
          ? require('@/assets/images/partner/courses/sort-up.svg')
          : require('@/assets/images/partner/courses/sort-down.svg'),"alt":"sort"},on:{"click":_vm.onSort}}):_vm._e()]),_c('div',{staticClass:"cw-main__results"},[_c('LxpPaginationWrapper',{ref:"courselistwrapper",attrs:{"dataFetchURL":_vm.dataFetchURL,"authToken":`Bearer ${_vm.$keycloak.token}`,"nextPageLabel":"next","pageSizeValue":20,"showNumberPagination":""},on:{"count":_vm.updateCount,"card-subtitle-click":_vm.onSubTitleClick},scopedSlots:_vm._u([{key:"emptyState",fn:function(){return [_c('div',{staticClass:"cw-main__empty"},[_c('div',{staticClass:"cw-main__results--empty"},[_c('img',{staticClass:"cw-main__empty--img",attrs:{"src":require("@/assets/images/partner/courses/empty-courses.svg"),"alt":"empty"}}),(_vm.search !== '' || _vm.showFilterPills)?_c('list-empty-state',{attrs:{"search":_vm.search,"hasFilters":_vm.showFilterPills}}):_c('div',{staticClass:"cw-main__results--empty-div"},[_c('p',[_vm._v("Unfortunately, there are no courses available yet.")]),_c('span',[_vm._v("This may be due to an API call issue or because the connection has not been made yet.")])])],1)])]},proxy:true},{key:"data",fn:function(slotProps){return [_c('div',{staticClass:"w-100"},_vm._l((slotProps.data),function(course,i){return _c('div',{key:i,staticClass:"cw-main__results--card"},[_c('LxpCourseCard',{attrs:{"card-sub-title":course.org_short_name,"card-title":course.name,"card-image":course.course_image_url
                  ? course.course_image_url
                  : require('@/assets/images/partner/courses/thumbnail.png'),"default-image":require('@/assets/images/partner/courses/thumbnail.png'),"tag-class":"ls-resources__card--tag","footer-class":"course__card--footer","id":`course-card-${course.course_id}`},on:{"card-click":function($event){return _vm.$parent.$emit('card-click', course.course_id)},"card-subtitle-click":function($event){return _vm.$parent.$emit('card-subtitle-click', course.org_short_name)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"course-card__footer"},[_c('div',{class:[
                      'course-card__footer--div',
                      `course-card__${course.pacing}`
                    ]},[_c('img',{attrs:{"src":course.pacing === 'self'
                          ? require('@/assets/images/partner/courses/self-paced-icon.svg')
                          : require('@/assets/images/partner/courses/instructor-led-icon.svg'),"alt":"pacing"}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.pacing(course)))])]),_c('div',{staticClass:"course-card__footer--div course-card__language"},[_c('img',{attrs:{"src":"https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/language.svg","alt":"pacing"}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(course.language))])])])]},proxy:true}],null,true)}),_c('course-details-popover',{attrs:{"course":course}})],1)}),0)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }